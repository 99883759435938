<template>
  <v-row v-if="request.processStatus === requestStates.OPEN">
    <v-col cols="12" md="auto">
      <div class="overline">{{ requested }}</div>
      <div class="body-1">
        <formatted-date :date="request.createdOn"></formatted-date>
      </div>
    </v-col>

    <v-col cols="12" md="auto">
      <div class="overline">{{ valid }}</div>
      <div class="body-1"><span>{{ toLocaleDateString(request.expiryDate, dateFormatConfig) }} {{ timeString }}</span></div>
    </v-col>
  </v-row>

  <v-row v-else-if="request.processStatus === requestStates.ACCEPTED && request.acceptedOn">
    <v-col cols="12" md="auto">
      <div class="overline">{{ accepted }}</div>
      <div class="body-1">
        <formatted-date :date="request.acceptedOn"></formatted-date>
      </div>
    </v-col>
  </v-row>

  <v-row v-else-if="request.processStatus === requestStates.CANCELED && request.canceledOn">
    <v-col cols="12" md="auto">
      <div class="overline">{{ canceled }}</div>
      <div class="body-1">
        <formatted-date :date="request.canceledOn"></formatted-date>
      </div>
    </v-col>
  </v-row>

  <v-row v-else-if="request.processStatus === requestStates.DECLINED && request.declinedOn">
    <v-col cols="12" md="auto">
      <div class="overline">{{ declined }}</div>
      <div class="body-1">
        <formatted-date :date="request.declinedOn"></formatted-date>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { getDaysUntil, toLocaleDateString } from '@/helpers/datetime.helper'
import requestStates from '@/types/RequestStates'

import FormattedDate from '../FormattedDate'
import config from '@/config/config'

export default {
  name: 'RequestValidity',
  components: {
    FormattedDate
  },
  props: {
    state: {
      type: String,
      required: true,
      validator: (state) => requestStates.isValidState(state)
    },
    request: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      getDaysUntil,
      toLocaleDateString,
      requestStates
    }
  },
  computed: {
    requested () {
      return this.$t(`${this.componentTranslationKey}.validity.requested`)
    },
    valid () {
      return this.$t(`${this.componentTranslationKey}.validity.valid`)
    },
    remaining () {
      return this.$t(`${this.componentTranslationKey}.validity.remaining`)
    },
    accepted () {
      return this.$t(`${this.componentTranslationKey}.validity.accepted`)
    },
    canceled () {
      return this.$t(`${this.componentTranslationKey}.validity.canceled`)
    },
    declined () {
      return this.$t(`${this.componentTranslationKey}.validity.declined`)
    },
    timeString () {
      return this.$t('global.units.timeString')
    },
    dateFormatConfig () {
      return config.get('i18n.defaultLocalDateTimeStringOptions').config
    }
  },
  inject: [
    'componentTranslationKey'
  ]
}
</script>
