<template>
  <div>
    <h3 class="mt-10 text-h6">{{ heading }}</h3>

    <div class="row">
      <div class="col-md-auto col-12">
        <div class="overline">{{ createdBy }}</div>
        <div class="body-1">{{ userName }}</div>
      </div>

      <div class="col-md-auto col-12">
        <div class="overline">{{ createdOn }}</div>
        <div class="body-1">
          <formatted-date :date="creationDate"></formatted-date>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormattedDate from '../FormattedDate'

export default {
  name: 'RequestCreationInformation',
  components: {
    FormattedDate
  },
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  computed: {
    userName () {
      return this.request.createdUser.preferredUsername
    },
    creationDate () {
      return this.request.createdOn
    },
    heading () {
      return this.$t(`${this.componentTranslationKey}.creationInformation.heading`)
    },
    createdBy () {
      return this.$t(`${this.componentTranslationKey}.creationInformation.createdBy`)
    },
    createdOn () {
      return this.$t(`${this.componentTranslationKey}.creationInformation.createdOn`)
    }
  },
  inject: [
    'componentTranslationKey'
  ]
}
</script>
