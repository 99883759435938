<template>
  <div>
    <page-header :heading="pageHeading" :sub-heading="pageSubHeading" large-content>
      <div class="mb-2 hidden-xs-only">
        <v-btn-toggle
          v-model="period"
          color="primary"
          mandatory
        >
          <v-btn
            v-for="({ value, text }) in periods"
            :key="value"
            :value="value"
            small
          >
            {{ text }}
          </v-btn>
        </v-btn-toggle>
      </div>

      <div class="mb-2 hidden-sm-and-up">
        <v-select
          v-model="period"
          :items="periods"
          :label="periodsLabel"
          dense
          hide-details
        ></v-select>
      </div>

      <statistics :data="data"></statistics>
    </page-header>

    <div class="mt-8">
      <v-btn color="primary" class="mb-4" :to="{ name: 'createRequest' }">{{ createRequestText }}</v-btn>

      <request-management></request-management>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import PageHeader from '@/components/PageHeader'
import RequestManagement from '@/components/requests/RequestManagement'
import Statistics from '@/components/requests/Statistics'

import i18n from '@/mixins/i18n.mixin'
import Page from '@/mixins/page.mixin'
import requestStates from '@/types/RequestStates'
import { scrollToElement } from '@/helpers/utility.helper'

export default {
  name: 'Requests',
  components: {
    PageHeader,
    RequestManagement,
    Statistics
  },
  mixins: [
    i18n,
    Page
  ],
  data: () => ({
    period: 'last_24h'
  }),
  created () {
    scrollToElement(document.body)
    this.fetchStatistics()
  },
  methods: {
    ...mapActions('statistics', ['fetchStatistics']),
    getPeriodLabel (period) {
      const keyMappings = { last_24h: 'day', last_week: 'week', last_month: 'month' }

      return this.$tp(`components.statistics.periods.${keyMappings[period]}`)
    },
    getStateLabel (state) {
      return this.$t(`global.requests.states.${state}`)
    }
  },
  computed: {
    ...mapGetters('statistics', ['statistics']),
    periods () {
      return Object.keys(this.statistics || {}).map((period) => ({ value: period, text: this.getPeriodLabel(period) }))
    },
    data () {
      if (Object.keys(this.statistics).length === 0) {
        return []
      }

      return Object.keys(this.statistics[this.period])
        .map(key => {
          const state = key.replace('count_', '')

          return {
            count: this.statistics[this.period][key],
            color: requestStates.getColor(state),
            title: this.getStateLabel(state)
          }
        })
    },
    createRequestText () {
      return this.$tp('components.management.actions.create')
    },
    periodsLabel () {
      return this.$tp('components.statistics.periods.label')
    }
  }
}
</script>
