<template>
  <div>
    <div class="text-h5 font-weight-bold">
      <copy :text="`${customer.givenName} ${customer.familyName} ${!customer.familyName && !customer.givenName ? customer.companyName : ''}`"></copy>
      <v-chip
        :color="requestStates.getColor(state)"
        text-color="white"
        class="ml-2"
        small
      >
        {{ requestStates.getTranslation(state) }}
      </v-chip>
    </div>

    <copy
      v-if="customer.email"
      root-element="div"
      :root-element-props="{ class: 'body-1' }"
      :text="customer.email"
    ></copy>

    <copy
      v-if="customerId"
      root-element="div"
      :root-element-props="{ class: 'body-2' }"
      :text="customerId"
      :display="customerIdText"
    ></copy>

    <div class="body-2 red--text">
      <template v-if="customer.isBusinessCustomer">
        <v-icon color="red" small>{{ $icons.mdiDomain }}</v-icon> {{ businessCustomer }}
      </template>

      <template v-else>
        <v-icon color="red" small>{{ $icons.mdiAccount }}</v-icon> {{ privateCustomer }}
      </template>
    </div>
  </div>
</template>

<script>
import requestStates from '@/types/RequestStates'

import Copy from '../Copy'

export default {
  name: 'RequestCustomerDetails',
  components: {
    Copy
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
    state: {
      type: String,
      required: true,
      validator: (state) => requestStates.isValidState(state)
    },
    customerId: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      requestStates
    }
  },
  computed: {
    customerIdText () {
      return this.$t(`${this.componentTranslationKey}.customer.customerId`, { customerId: this.customerId })
    },
    businessCustomer () {
      return this.$t(`${this.componentTranslationKey}.customer.businessCustomer`)
    },
    privateCustomer () {
      return this.$t(`${this.componentTranslationKey}.customer.privateCustomer`)
    }
  },
  inject: [
    'componentTranslationKey'
  ]
}
</script>
