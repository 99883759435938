<template>
  <div>
    <request-table></request-table>
    <request-side-panel></request-side-panel>
  </div>
</template>

<script>
import RequestSidePanel from './RequestSidePanel'
import RequestTable from './RequestTable'

export default {
  name: 'RequestManagement',
  components: {
    RequestSidePanel,
    RequestTable
  },
  computed: {
    componentTranslationKey () {
      return `${this.pageTranslationKey}.components.management`
    }
  },
  inject: [
    '$tp',
    'pageTranslationKey'
  ],
  provide () {
    return {
      componentTranslationKey: this.componentTranslationKey
    }
  }
}
</script>
