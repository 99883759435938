<template>
  <div>
    <div class="text-h6">{{ title }}</div>

    <div class="overline">{{ productOverline }}</div>
    <div class="body-1">{{ contractSummary.baseSegment.productName }}</div>

    <div class="mt-4">
      <embedded-object :source="document.embedLink"></embedded-object>
    </div>
  </div>
</template>

<script>
import EmbeddedObject from '../EmbeddedObject'

export default {
  name: 'RequestContractSummary',
  components: {
    EmbeddedObject
  },
  props: {
    contractSummary: {
      type: Object,
      required: true
    },
    document: {
      type: Object,
      required: true
    }
  },
  computed: {
    title () {
      return this.$t(`${this.componentTranslationKey}.contractSummary.title`)
    },
    productOverline () {
      return this.$t(`${this.componentTranslationKey}.contractSummary.productOverline`)
    }
  },
  inject: [
    'componentTranslationKey'
  ]
}
</script>
