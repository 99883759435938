<template>
  <div>
    <div class="text-h6">{{ title }}</div>
    <div class="text-subtitle-1">Dokumentenmappe: {{ documentFolder.name }}</div>

    <div class="mt-2">
      <v-expansion-panels>
        <v-expansion-panel
          v-for="({ embedLink, fileUuid, title }) in documents"
          :key="fileUuid"
        >
          <v-expansion-panel-header>
            <template #actions>
              <v-icon class="expansion-panel-icon">$expand</v-icon>
            </template>

            <v-row align="center" no-gutters class="expansion-panel-header">
              <v-col cols="auto" class="mr-2">
                <v-icon>{{ $icons.mdiFilePdfBox }}</v-icon>
              </v-col>

              <v-col>
                <span class="font-weight-medium">
                  {{ title }}
                </span>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <embedded-object
              :source="embedLink"
              type="application/pdf"
              width="100%"
              height="200"
            ></embedded-object>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import EmbeddedObject from '../EmbeddedObject'

export default {
  name: 'RequestDocuments',
  components: {
    EmbeddedObject
  },
  props: {
    documents: {
      type: Array,
      required: true
    },
    documentFolder: {
      type: Object,
      required: true
    }
  },
  computed: {
    title () {
      return this.$t(`${this.componentTranslationKey}.documents.title`)
    }
  },
  inject: [
    'componentTranslationKey'
  ]
}
</script>
