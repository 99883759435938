<template>
  <v-row>
    <v-col
      v-for="({ to }, i) in requestStates.getTransitions(state)"
      :key="`${to}-${i}`"
      cols="auto"
    >
      <v-btn
        :color="requestStates.getColor(to)"
        dark
        small
        @click="showDialog(to)"
      >
        {{ requestStates.getTransitionTranslation(to) }}
      </v-btn>
    </v-col>

    <confirmation-dialog
      v-model="dialog"
      :title="getDialogTitle(transitionTo)"
      :text="dialogText"
      :cancel="dialogCancel"
      :confirm="dialogConfirm"
      @cancel="resetDialog"
      @confirm="updateState"
    ></confirmation-dialog>
  </v-row>
</template>

<script>
import requestStates from '@/types/RequestStates'

import ConfirmationDialog from '../ConfirmationDialog'

export default {
  name: 'RequestTransitions',
  components: {
    ConfirmationDialog
  },
  props: {
    state: {
      type: String,
      required: true,
      validator: (state) => requestStates.isValidState(state)
    }
  },
  data () {
    return {
      requestStates,
      transitionTo: null,
      dialog: false
    }
  },
  methods: {
    updateState () {
      this.$emit('update', this.transitionTo)
      this.resetDialog()
    },
    showDialog (to) {
      this.transitionTo = to
      this.dialog = true
    },
    resetDialog () {
      this.dialog = false
      this.transitionTo = null
    },
    getDialogTitle (to) {
      const newState = requestStates.getTransitionTranslation(to)
      return this.$t(`${this.componentTranslationKey}.transitions.dialog.title`, { newState })
    }
  },
  computed: {
    dialogText () {
      return this.$t(`${this.componentTranslationKey}.transitions.dialog.text`)
    },
    dialogCancel () {
      return this.$t(`${this.componentTranslationKey}.transitions.dialog.cancel`)
    },
    dialogConfirm () {
      return this.$t(`${this.componentTranslationKey}.transitions.dialog.confirm`)
    }
  },
  inject: [
    'componentTranslationKey'
  ],
  watch: {
    dialog (open) {
      if (!open) {
        this.transitionTo = null
      }
    }
  }
}
</script>
