<template>
  <div>
    <v-row justify="space-between">
      <v-col cols="10">
        <request-customer-details
          :customer="request.customer"
          :state="request.processStatus"
          :customer-id="request.surrogatCustomerUid"
        ></request-customer-details>
      </v-col>
      <v-col cols="auto">
        <component-guard :roles="['admin', 'manager']">
          <v-dialog v-model="editDialog" max-width="500px">
            <template #activator="{ attrs, on }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon>{{ $icons.mdiPencil }}</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <help-dialog :path="editFormHeading" type="span"></help-dialog>
              </v-card-title>

              <v-card-text>
                <form-builder
                  ref="form"
                  :config="editFormConfig"
                  :data="editForm.data"
                  :errors="editForm.errors"
                  :rules="editForm.rules"
                  form-name="details.editForm"
                  hide-footer
                  @change="handleChange"
                ></form-builder>
              </v-card-text>

              <v-card-actions>
                <v-btn color="primary" text @click="closeEditDialog">
                  {{ editFormCancelButton }}
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                  :disabled="isEditButtonDisabled"
                  :loading="updatingRequest"
                  color="green"
                  text
                  @click="handleEditSubmit"
                >
                  {{ editFormSubmitButton }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </component-guard>
      </v-col>
    </v-row>

    <div class="mt-8">
      <request-validity :request="request" :state="request.processStatus"></request-validity>
    </div>

    <div
      class="mt-8"
      v-if="!noStates && requestStates.hasTransitions(request.processStatus) || request.processStatus === 'expired'"
    >
      <request-transitions
        :state="request.processStatus"
        @update="handleUpdate"
      ></request-transitions>
    </div>

    <div class="mt-16">
      <request-contract-summary
        :contract-summary="request.contractSummaryTemplate"
        :document="request.vzfDocument"
      ></request-contract-summary>
    </div>

    <div class="mt-16" v-if="request.vviDocuments.length > 0">
      <request-documents :documents="request.vviDocuments" :document-folder="request.documentFolder"></request-documents>
    </div>

    <div class="mt-16" v-if="request.predecessorEeccRequestUuid">
      <preceding-request :request-uuid="request.predecessorEeccRequestUuid"></preceding-request>
    </div>

    <template v-if="request.comment">
      <h3 class="mt-10 text-h6">{{ commentHeading }}</h3>

      <v-container class="pa-0 mx-0 mb-0 mt-2">
        <v-row>
          <v-col>
            <span class="font-weight-bold">{{ request.createdUser.preferredUsername }}</span>
          </v-col>

          <v-col class="text-right body-2">
            <formatted-date :date="request.createdOn"></formatted-date>
          </v-col>
        </v-row>

        <p class="mt-2 body-2">{{ request.comment }}</p>
      </v-container>
    </template>

    <request-creation-information
      :request="request"
    ></request-creation-information>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import requestStates from '@/types/RequestStates'

import ComponentGuard from '../ComponentGuard'
import FormattedDate from '../FormattedDate'
import FormBuilder from '../form/FormBuilder'
import HelpDialog from '../HelpDialog'
import PrecedingRequest from './PrecedingRequest'
import RequestContractSummary from './RequestContractSummary'
import RequestCreationInformation from '@/components/requests/RequestCreationInformation'
import RequestCustomerDetails from './RequestCustomerDetails'
import RequestDocuments from './RequestDocuments'
import RequestTransitions from './RequestTransitions'
import RequestValidity from './RequestValidity'

import { getHttpValidationErrorDetails } from '@/helpers/api.helper'
import { email, required } from '@/helpers/validators.helpers'

export default {
  name: 'RequestDetailView',
  components: {
    ComponentGuard,
    FormattedDate,
    FormBuilder,
    HelpDialog,
    PrecedingRequest,
    RequestContractSummary,
    RequestCreationInformation,
    RequestCustomerDetails,
    RequestDocuments,
    RequestTransitions,
    RequestValidity
  },
  props: {
    request: {
      type: Object,
      required: true
    },
    noStates: {
      type: Boolean,
      default: false
    }
  },
  async created () {
    await this.fetchMailTemplates()
  },
  data () {
    return {
      requestStates,
      itemDocumentFolder: null,
      editDialog: false,
      editForm: {
        data: {
          email: '',
          customerId: '',
          mailTemplateUuid: ''
        },
        errors: {
          email: '',
          customerId: '',
          mailTemplateUuid: ''
        },
        rules: {
          email: [
            email,
            [required, { field: 'mailTemplateUuid' }]
          ],
          mailTemplateUuid: [
            [required, { field: 'email' }]
          ]
        }
      },
      editFormIsValid: true,
      editFormValidityWatcher: null
    }
  },
  methods: {
    ...mapActions('documentFolders', ['getDocumentFolder', 'getMemberships']),
    ...mapActions('mailTemplates', ['fetchMailTemplates']),
    ...mapActions('requests', ['updateRequest', 'getRequests']),
    ...mapMutations('requests', ['ACTIVE_REQUEST']),
    async loadDocumentFolder () {
      if (this.request) {
        const uuid = this.request.documentFolderUuid

        await this.getMemberships({ uuid })
        this.itemDocumentFolder = await this.getDocumentFolder({ uuid })
      }
    },
    handleUpdate (event) {
      this.$emit('update', event)
    },
    closeEditDialog () {
      this.editDialog = false
    },
    async handleEditSubmit () {
      const valid = this.$refs.form.validate()

      if (!valid) {
        return
      }

      const handlers = {
        '2xx': async () => {
          this.editDialog = false

          await this.getRequests()
          this.ACTIVE_REQUEST(this.request.uuid) // refresh active request

          return this.$t(`${this.componentTranslationKey}.editForm.messages.success`)
        },
        422: (response) => {
          const errors = getHttpValidationErrorDetails(response)

          if (errors) {
            errors.forEach(({ field, message }) => {
              let _field = field

              if (_field === 'surrogatCustomerUid') {
                _field = 'customerId'
              }

              this.$set(this.editForm.errors, _field, message)
            })
          }

          return this.$t('global.form.errorMessages.invalid')
        },
        '4xx': () => this.$t('global.form.errorMessages.generic')
      }

      const { email, customerId, mailTemplateUuid } = this.$refs.form.formData

      await this.updateRequest({
        uuid: this.request.uuid,
        email,
        customerId,
        mailTemplateUuid,
        handlers
      })
    },
    handleChange () {
      this.$refs.form.validate()
    }
  },
  computed: {
    ...mapGetters('documentFolders', ['memberships']),
    ...mapGetters('mailTemplates', ['mailTemplates']),
    ...mapGetters('requests', ['updatingRequest']),
    componentTranslationKey () {
      return `${this.pageTranslationKey}.components.details`
    },
    commentHeading () {
      return this.$t(`${this.componentTranslationKey}.comment.heading`)
    },
    email () {
      return this.request?.customer?.email ?? ''
    },
    mailTemplateUuid () {
      return this.request?.mailTemplateUuid ?? ''
    },
    customerId () {
      return this.request?.surrogatCustomerUid ?? ''
    },
    editFormHeading () {
      return `${this.componentTranslationKey}.editForm.heading`
    },
    editFormCancelButton () {
      return this.$t(`${this.componentTranslationKey}.editForm.buttons.cancel`)
    },
    editFormSubmitButton () {
      return this.$t(`${this.componentTranslationKey}.editForm.buttons.submit`)
    },
    isEditButtonDisabled () {
      return this.updatingRequest || !this.editFormIsValid
    },
    editFormConfig () {
      return [
        {
          name: 'email',
          component: 'v-text-field',
          props: {
            type: 'email'
          }
        },
        {
          name: 'customerId',
          component: 'v-text-field'
        },
        {
          name: 'mailTemplateUuid',
          component: 'v-autocomplete',
          props: {
            itemText: 'name',
            itemValue: 'uuid',
            items: this.mailTemplates,
            disabled: !this.hasMailTemplates,
            clearable: true
          }
        }
      ]
    },
    hasMailTemplates () {
      return this.mailTemplates.length > 0
    }
  },
  watch: {
    editDialog (open) {
      if (open) {
        this.$set(this.editForm.data, 'email', this.email)
        this.$set(this.editForm.data, 'customerId', this.customerId)
        this.$set(this.editForm.data, 'mailTemplateUuid', this.mailTemplateUuid)

        this.$nextTick(() => {
          this.$refs.form.reset()

          this.editFormValidityWatcher = this.$watch(() => this.$refs.form.isValid, (isValid) => {
            this.editFormIsValid = isValid
          })
        })

        return
      }

      if (this.editFormValidityWatcher) {
        this.editFormValidityWatcher()
        this.editFormValidityWatcher = null
      }
    }
  },
  inject: [
    'pageTranslationKey'
  ],
  provide () {
    return {
      componentTranslationKey: this.componentTranslationKey
    }
  }
}
</script>
