<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="3" xl="2">
          <v-row class="statistics">
            <v-col
              v-for="({count, title, color}, i) in data"
              :key="i"
              :style="{ color }"
              cols="4"
              md="12"
              lg="12"
            >
              <div class="text-caption">{{ title }}</div>
              <div>{{ count }}</div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="9" xl="10">
          <canvas ref="chart" class="chart"></canvas>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { Chart, BarController, BarElement, CategoryScale, LinearScale } from 'chart.js'
Chart.register(BarController, BarElement, CategoryScale, LinearScale)

export default {
  name: 'Statistics',
  props: ['data'],
  data: () => ({
    chart: null
  }),
  computed: {
    chartData () {
      return this.data ?? []
    },
    labels () {
      return this.chartData.map(({ title }) => title)
    },
    datasets () {
      return [
        {
          data: this.chartData.map(({ count }) => count),
          backgroundColor: this.chartData.map(({ color }) => color)
        }
      ]
    }
  },
  mounted () {
    const canvas = this.$refs.chart
    const ctx = canvas.getContext('2d')

    const data = {
      labels: this.labels,
      datasets: this.datasets
    }

    this.chart = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: {
        events: [],
        animation: false,
        plugins: {
          title: {
            display: false
          }
        },
        responsive: true,
        scales: {
          y: {
            ticks: {
              callback: (value) => value % 1 === 0 ? value : undefined
            }
          }
        }
      }
    })
  },
  watch: {
    datasets: {
      handler () {
        this.chart.data.datasets = this.datasets
        this.chart.update()
      },
      deep: true
    },
    labels: {
      handler () {
        this.chart.data.labels = this.labels
        this.chart.update()
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.statistics {
  margin-top: -0.375rem;
  margin-bottom: -0.375rem;
}

.statistics > [class*='col-'] {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.chart {
  max-height: 20rem;
}
</style>
