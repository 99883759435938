<template>
  <div v-if="requestUuid">
    <div class="text-h6 mb-2">{{ title }}</div>

    <div class="mt-2">
      <v-expansion-panels v-if="request">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <template #actions>
              <v-icon class="expansion-panel-icon">$expand</v-icon>
            </template>

            <v-row align="center" no-gutters class="expansion-panel-header">
              <v-col cols="auto" class="mr-2">
                <v-icon>{{ $icons.mdiFileDocumentEditOutline }}</v-icon>
              </v-col>

              <v-col>
                <span class="font-weight-medium">
                  {{ productName }}
                </span>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <request-detail-view :request="request"></request-detail-view>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div
        v-if="!request"
        class="text-center"
      >
        <v-progress-circular
          :size="32"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'PrecedingRequest',
  components: {
    RequestDetailView: () => import('./RequestDetailView')
  },
  props: {
    requestUuid: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      request: null
    }
  },
  async created () {
    this.request = await this.getRequest(this.requestUuid)
  },
  methods: mapActions('requests', ['getRequest']),
  computed: {
    productName () {
      return this.request?.contractSummaryTemplate?.baseSegment?.productName ?? ''
    },
    title () {
      return this.$t(`${this.componentTranslationKey}.precedingRequest.title`)
    }
  },
  inject: [
    'componentTranslationKey'
  ]
}
</script>
