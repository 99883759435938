<template>
  <side-panel v-model="sidePanel">
    <request-detail-view
      v-if="activeRequest"
      :request="activeRequest"
      @update="updateState"
    ></request-detail-view>

    <v-overlay :value="loadingState">
      <v-progress-circular
        :size="64"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </side-panel>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import RequestDetailView from './RequestDetailView'
import SidePanel from '../SidePanel'

import requestStates from '@/types/RequestStates'

export default {
  name: 'RequestSidePanel',
  components: {
    RequestDetailView,
    SidePanel
  },
  data () {
    return {
      sidePanel: false
    }
  },
  methods: {
    ...mapActions('requests', ['updateRequestState']),
    ...mapMutations('requests', ['ACTIVE_REQUEST']),
    async updateState (newState) {
      if (!this.activeRequest) {
        return
      }

      const currentState = this.activeRequest.processStatus

      if (!requestStates.canTransition(currentState, newState)) {
        return
      }

      const uuid = this.activeRequest.uuid

      await this.updateRequestState({ uuid, newState })

      this.ACTIVE_REQUEST(uuid) // refresh active request
    }
  },
  computed: {
    ...mapGetters('requests', ['requests', 'total', 'loading', 'loadingState', 'activeRequest']),
    stateTranslationKey () {
      return `${this.pageTranslationKey}.states`
    }
  },
  inject: [
    '$tp',
    'componentTranslationKey',
    'pageTranslationKey'
  ],
  watch: {
    activeRequest (value) {
      if (value !== null) {
        this.sidePanel = true
      }
    },
    sidePanel (open) {
      if (!open) {
        this.ACTIVE_REQUEST(null)
      }
    }
  }
}
</script>
